import React from 'react'

import glass100 from '../../../assets/imgs/icons/dryspell-cups-05.svg'
import glass75 from '../../../assets/imgs/icons/dryspell-cups-04.svg'
import glass50 from '../../../assets/imgs/icons/dryspell-cups-03.svg'
import glass25 from '../../../assets/imgs/icons/dryspell-cups-02.svg'
import glass0 from '../../../assets/imgs/icons/dryspell-cups-01.svg'

const DRINK_FULLNESS = {
    100: glass100,
    75: glass75,
    50: glass50,
    25: glass25,
    0: glass0
}

class DrinkCounter extends React.Component {
    state = {
        fullness: 100
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.activeCount !== this.props.activeCount) {
            if (this.state.fullness > 0 && this.props.number <= this.props.activeCount) {
                setTimeout(this.drainCup, 50 * (this.props.number - prevProps.activeCount))
            } else if (this.state.fullness < 100 && this.props.number > this.props.activeCount) {
                setTimeout(this.fillCup, 50 * (this.props.number - this.props.activeCount))
            }
        }
    }

    fillCup = () => {
        let { fullness } = this.state
        if (fullness < 100) {
            fullness = fullness + 25
            this.setState({ fullness })
            setTimeout(this.fillCup, 50, fullness)
        }
    }

    drainCup = () => {
        let { fullness } = this.state
        if (fullness > 0) {
            fullness = fullness - 25
            this.setState({ fullness })
            setTimeout(this.drainCup, 50, fullness)
        }
    }

    render = () => {
        return (
            <div className={`drink-counter ${this.state.fullness === 0 ? 'active' : ''}`} onClick={() => this.props.onSetDrinkCount(this.props.number)}>
                <img src={DRINK_FULLNESS[this.state.fullness]} alt="Full Drink" className="icon" />
                <div className="count">{this.props.number}{this.props.number === 6 ? '+' : null}</div>
            </div>
        )
    }
}

export default DrinkCounter
