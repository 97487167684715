import React from 'react'
import { connect } from 'react-redux'

import { Routes, Route, Link, Navigate } from 'react-router-dom'
import { withRouterProps } from './utils/with-router-props'

import { apiResourceEndpoint, routes } from '../constants/routes'

import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { handleFirebaseAuth, removeAuth } from '../actions/auth'
// import { fillDates } from '../actions/local-api'
import { callApi } from '../actions/api'

import AppHeader from './layout/app-header'

// init pages
import WelcomeCheers from './app/init/cheers'

import OnboardIndex from './app/onboard/index'
// import OnboardPaymentConfirmation from './app/onboard/payment-confirmation'
import DayView from './app/days/view'
import GoalsIndex from './app/goals'
import History from './app/days/history'
// import ReportsIndex from './app/reports/index'
import SettingsIndex from './app/settings/index'

import Privacy from './pages/privacy'

import CalendarIcon from '../assets/imgs/icon-calendar-01.svg'
// import ReportsIcon from '../assets/imgs/icon-reports-01.svg'
import SettingsIcon from '../assets/imgs/icon-settings-01.svg'
import GoalsIcon from '../assets/imgs/icon-badge-01.svg'

import '../assets/scss/app.scss'


class App extends React.Component {
    state = {
        redirectToWelcome: true,
        fireauth: null
    }

    componentDidMount = () => {
        const { dispatch, firebaseApp } = this.props
        const fireauth = getAuth(firebaseApp)
        this.setState({ fireauth })
        onAuthStateChanged(fireauth, firebaseUser => {
            if (firebaseUser) {
                dispatch(handleFirebaseAuth(firebaseUser))
                this.props.dispatch(callApi(apiResourceEndpoint('user', 'CREATE'), {
                    settings: this.props.settings,
                    dates: this.props.dates

                }))
            } else {
                dispatch(removeAuth())
                this.setState({ redirectToWelcome: true })
            }
        })
    }

    turnOffWelcomePath = () => {
        this.setState({
            redirectToWelcome: false
        })
    }

    render = () => {
        if (this.state.redirectToWelcome && this.props.location.pathname.indexOf(routes.welcome) !== 0) {
            return <Navigate to={routes.welcome} />
        }
        return (
            <div id="app">
                <AppHeader />
                <div className="content">
                    <Routes>
                        <Route path={routes.welcome} element={<WelcomeCheers onTurnOffWelcomePath={this.turnOffWelcomePath} />} />
                        <Route path={`${routes.onboardIndex}*`} element={<OnboardIndex />} />
                        {/* <Route path={routes.onboardPaymentConfirmation} element={<OnboardPaymentConfirmation />} /> */}
                        <Route path={routes.today} element={<DayView />} />
                        <Route path={routes.calendarDay} element={<DayView />} />
                        <Route path={routes.calendarIndex} element={<History />} />
                        {/* <Route path={`${routes.reportsIndex}*`} element={<ReportsIndex />} /> */}
                        <Route path={`${routes.goalsIndex}*`} element={<GoalsIndex />} />
                        <Route path={`${routes.settingsIndex}*`} element={<SettingsIndex />} />
                        <Route path={routes.index} element={<DayView />} />
                        <Route path={routes.privacyPolicy} element={<Privacy />} />
                        <Route path="*" element={<div>Need 404 page</div>} />
                    </Routes>
                </div>
                { this.props.settings.get('onboardComplete') && this.props.location.pathname !== routes.welcome &&
                    <div className="footer">
                        <div className="menu">
                            <Link className="item" to={routes.calendarIndex}>
                                <img src={CalendarIcon} alt="History" />
                            </Link>
                            <Link className="item" to={routes.goalsIndex}>
                                <img src={GoalsIcon} alt="Goals" />
                            </Link>
                            <Link className="item" to={routes.settingsIndex}>
                                <img src={SettingsIcon} alt="Settings" />
                            </Link>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        settings: state.get('settings'),
        dates: state.get('dates')
    }
}

export default withRouterProps(connect(mapStateToProps)(App))
