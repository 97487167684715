import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import iconHalf from '../../../assets/imgs/icons/dryspell-icon-half.svg'
// import iconX from '../../../assets/imgs/icons/dryspell-icon-x.svg'

class Moderator extends React.Component {
    state = {}

    renderGraphDays = (drinks, maxDrinks) => {
        let totalDrinks = drinks.totalDrinks
        let days = []
        // NOTE: Really this is just a counter of how many drinks you have left this week
        // TODO: Fail message.....
        for (let i = 0; i < maxDrinks; i = i + 1) {
            if (i < totalDrinks) {
                days.push(<div key={i} className="day orange"><img src={iconHalf} alt="half full" /></div>)
            } else {
                days.push(<div key={i} className="day" />)
            }
        }
        return days
    }

    renderStatus = (drinks, maxDrinks) => {
        return (
            <>
                <div className="value">{ drinks.totalDrinks } <span>|</span> { maxDrinks }</div>
                <div className="label">drinks this week</div>
            </>
        )
    }

    render = () => {
        let { goals } = this.props
        let curWeek = moment().subtract(1, 'days').format('YYYY-w')
        if (!goals || !goals[curWeek]) {
            return (
                <div className="goals-wrapper">
                    <div className="status">
                        <div className="label">No Info Yet. Go add some days!</div>
                    </div>
                </div>
            )
        }
        // TODO: We need to know male / female to know 7 or 14.....
        let maxDrinks = 14
        return (
            <div className="goals-wrapper">
                <div className="status">
                    { this.renderStatus(goals[curWeek], maxDrinks) }
                    <div className="graph glasses">
                        { this.renderGraphDays(goals[curWeek], maxDrinks) }
                    </div>
                </div>
                <div className="badges">
                    {/* Sample Badges */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        goals: state.getIn(['calculations', 'goals', 'moderator'])
    }
}


export default connect(mapStateToProps)(Moderator)
