import { fromJS } from 'immutable'

const INITIAL_STATE = fromJS({})

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case `SAVE_AUTH`:
        return state
            .set('isAnonymous', action.auth.isAnonymous)
            .set('hasAuthId', action.auth.uid ? true : false)
            .set('providers', action.auth.providerData.map(pd => pd.providerId))
    // case `SAVE_ANONYMOUS_AUTH`:
    //     return state
    //         .set('isAnonymous', action.auth.isAnonymous)
    case `REMOVE_AUTH`:
    case `KILL_STATE`:
        return fromJS({})
    default:
        return state
    }
}

export default authReducer
