import React from 'react'

class AboutDrySpell extends React.Component {
    state = {}

    render = () => {
        return (
            <div className="onboard">
                <div className="options">
                    <div className="item">
                        <div className="description legible">
                            <h1>
                                Dry Spell came about as part of a New Year's resolution.<br/>
                            </h1>
                            <p>
                                Inspired by "Dry January" trends, I resolved to not drink more days than I do drink. Needing a way to track my progress,
                                I turned to my partner at <a href="http://www.shadyhillstudios.com/">Shady Hill Studios</a> with the idea of building a simple web app to track my progress.
                            </p>
                            <p>
                                Together, we made Dry Spell!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutDrySpell
