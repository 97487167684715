import React from 'react'
import { connect } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'

import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'

// import { auth } from 'firebaseui'
import { getAuth, GoogleAuthProvider, GithubAuthProvider, signInWithPopup } from 'firebase/auth'
import { registerWithEmailAndPass, signInWithEmailAndPass, anonymousSignIn } from '../../../actions/auth'
// import { manageApiData } from '../../../actions/local-api'
import { routes } from '../../../constants/routes'

class RegisterLogin extends React.Component {
    state = {
        activeAction: this.props.fromSettings ? 'LOGIN' : 'REGISTER',
        hasSkipped: false,
        shouldNavigate: false,
        showEmailPass: false
    }

    // componentDidMount = () => {
    //     const stateAuth = this.props.auth
    //     console.log(stateAuth)
    //     if (stateAuth && ((stateAuth.has('authEmail') && stateAuth.get('authEmail') !== '') || stateAuth.get('isAnonymous'))) {
    //         this.setState({ shouldNavigate: true })
    //         // this.props.onAdvancePaymentStep()
    //     }
    //     const fireauth = getAuth()
    //     const uiConfig = {
    //         signInFlow: 'popup',
    //         autoUpgradeAnonymousUsers: true,
    //         callbacks: {
    //             signInSuccessWithAuthResult: function (authResult, redirectUrl) {
    //                 return false
    //             },
    //             signInFailure: function (error) {
    //                 if (error.code !== 'firebaseui/anonymous-upgrade-merge-conflict') {
    //                     return Promise.resolve()
    //                 }
    //                 // The credential the user tried to sign in with.
    //                 let cred = error.credential
    //                 // Copy data from anonymous user to permanent user and delete anonymous
    //                 // user.
    //                 // ...
    //                 // Finish sign-in after data is copied.
    //                 return stateAuth.signInWithCredential(cred)
    //             }
    //         },
    //         signInOptions: [
    //             GoogleAuthProvider.PROVIDER_ID,
    //             GithubAuthProvider.PROVIDER_ID
    //         ]
    //     }
    //     let ui = auth.AuthUI.getInstance() || new auth.AuthUI(fireauth)
    //     ui.start('#firebaseui-auth-container', uiConfig)
    // }

    handleGoogleSignIn = () => {
        const fireauth = getAuth()
        const provider = new GoogleAuthProvider()
        // provider.setCustomParameters({
        //     prompt: 'consent',
        //     login_hint: 'user@cordina.io',
        //     tenant: '77ffd5bf-ed07-470c-97fa-ad09b0abde1d'
        // })
        signInWithPopup(fireauth, provider)
            .then((result) => {
                // User is signed in.
                // IdP data available in result.additionalUserInfo.profile.
                console.log('I think this was taken care of somewhere else?')
                // Get the OAuth access token and ID Token
                // const credential = OAuthProvider.credentialFromResult(result)
                // const accessToken = credential.accessToken
                // const idToken = credential.idToken
                // console.log('we got a response?')
                // console.log(accessToken)
                // console.log(idToken)
            })
            .catch((error) => {
                // Handle error.
            })
    }

    handleGithubSignIn = () => {
        const fireauth = getAuth()
        const provider = new GithubAuthProvider()
        signInWithPopup(fireauth, provider)
            .then((result) => {
                // User is signed in.
                // IdP data available in result.additionalUserInfo.profile.
                console.log('I think this was taken care of somewhere else?')
                // Get the OAuth access token and ID Token
                // const credential = OAuthProvider.credentialFromResult(result)
                // const accessToken = credential.accessToken
                // const idToken = credential.idToken
                // console.log('we got a response?')
                // console.log(accessToken)
                // console.log(idToken)
            })
            .catch((error) => {
                // Handle error.
            })
    }

    handleAnonymousSignIn = () => {
        this.props.dispatch(anonymousSignIn(getAuth(), -1))
        // this.props.dispatch(manageApiData({
        //     onboardComplete: true,
        //     _key: 'SETTINGS'
        // }))
        // this.setState({
        //     hasSkipped: true,
        //     shouldNavigate: true
        // })
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.auth.has('hasAuthId') && this.props.auth.get('hasAuthId')) {
            this.setState({ shouldNavigate: true })
        }
    }

    successFX = () => {
        this.setState({
            shouldNavigate: true
        })
    }

    submitFX = (formData, formId) => {
        if (this.state.activeAction === 'LOGIN') {
            this.props.dispatch(signInWithEmailAndPass(getAuth(), formData.email, formData.password, formId))
        } else {
            this.props.dispatch(registerWithEmailAndPass(getAuth(), formData.email, formData.password, formId))
        }
    }

    toggleActiveAction = (action) => {
        this.setState({ activeAction: action })
    }

    render = () => {
        if (this.state.shouldNavigate) {
            if (this.props.fromSettings) {
                return <Navigate to={routes.today} />
            } else if (this.state.hasSkipped) {
                return <Navigate to={routes.today} />
            }
            return <Navigate to={routes.onboardPremium} />
        }
        return (
            <div className="onboard">
                <div className="description">
                    <div className="prompt">
                        Register with one of our preconfigured auth providers, or you chose to stay anonymous.
                    </div>
                </div>
                {
                    this.state.activeAction === 'LOGIN' &&
                    <h1>Login</h1>
                }
                {
                    this.state.activeAction !== 'LOGIN' &&
                    <h1>Register</h1>
                }
                <hr/>
                <div className="login-options">
                    <span className="btn" onClick={this.handleGoogleSignIn}>
                        Google
                    </span>
                    <span className="btn" onClick={this.handleGithubSignIn}>
                        Github
                    </span>
                    <span className="btn" onClick={() => this.setState({ showEmailPass: true })}>
                        Register / Login with Email &amp; Pass
                    </span>
                    <span className="btn" onClick={this.handleAnonymousSignIn}>
                        Stay Anonymous
                    </span>
                    {
                        this.state.showEmailPass &&
                        <>
                            <SHSForm
                                submitFX={this.submitFX}
                                successFX={this.successFX}
                                buttonLabel={this.state.activeAction === 'LOGIN' ? 'Login' : 'Register'}
                                className="test">
                                <InputElement
                                    type="email"
                                    id="email"
                                    placeholder="user@example.com"
                                    label="Email Address"
                                    errorMessage="Please provide a valid email address"
                                    required />
                                <InputElement
                                    type="password"
                                    id="password"
                                    placeholder="A secret word or phrase"
                                    label="Password"
                                    errorMessage="Password is required to be a minimum of 8 characters"
                                    required />
                            </SHSForm>
                            {
                                this.state.activeAction === 'LOGIN' ?
                                    <div className="toggle" onClick={() => this.toggleActiveAction('REGISTER')}>
                                        <p>Need to register? Click here.</p>
                                    </div> :
                                    <div className="toggle" onClick={() => this.toggleActiveAction('LOGIN')}>
                                        <p>Already registered? Click to login.</p>
                                    </div>
                            }
                        </>
                    }
                </div>
                <p className="mt-4">
                    DrySpell saves app data to our servers so you can retrieve your data from any device.
                    We do not store any personally identifiable information. Please read our <Link to={routes.privacyPolicy}>Privacy Policy</Link> for more information.
                </p>
                {
                    this.props.fromSettings &&
                    <Link to={routes.onboardIndex} className="back-btn">Go back</Link>
                }
                {/* <p className="d-flex flex-column align-items-center justify-content-center" style={{ marginTop: '7.5rem' }}>
                    Prefer to stay anonymous. No problem.<br />
                    <span className="clickable" onClick={this.handleSkipOnboard}>
                        Click here to continue
                    </span>
                </p> */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.get('auth')
    }
}

export default connect(mapStateToProps)(RegisterLogin)
