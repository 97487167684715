import React from 'react'

class Privacy extends React.Component {
    render = () => {
        return (
            <div className="onboard">
                <h1>DrySpell Privacy Policy</h1>

                <p>
                    Shady Hill Studios LLC (the "Company"), the makers of dryspell.app, is committed to maintaining robust privacy protections for its users.
                    Our Privacy Policy ("Privacy Policy") is designed to help you understand how we collect, use and safeguard the information you provide to us and to
                    assist you in making informed decisions when using our Service.
                </p>

                <p>
                    For purposes of this Agreement, "Site" refers to the website located at dryspell.app, which can be accessed at https://dryspell.app/.
                </p>
                <p>
                    "Service" refers to the Company's services accessed via the Site, in which users can track how much they do or do not drink alcohol over a given period of time.
                </p>
                <p>
                    The terms "we," "us," and "our" refer to the Company.
                </p>
                <p>
                    "You" refers to you, as a user of our Site or our Service.
                </p>
                <p>
                    By accessing our Site or our Service, you accept our Privacy Policy, and you consent to our collection, storage, use and disclosure of your Personal Information as described in this Privacy Policy.
                </p>

                <h3>I. Information we Collect</h3>

                <p>
                    We collect "Non-Personal Information." Non-Personal Information includes information that cannot be used to personally identify you,
                    such as anonymous usage data, general demographic information we may collect, referring/exit pages and URLs, platform types, preferences you submit and preferences
                    that are generated based on the data you submit and number of clicks.
                </p>

                <h3>1. Information collected via Technology</h3>
                <p>
                    To activate the Service you do not need to submit any Personal Information other than use one of our oAuth providers.
                    To use the Service thereafter, you do not need to submit further Personal Information.
                </p>

                <h3>2. Information you provide us by registering for an account</h3>

                <p>
                    In addition to the information provided automatically by your browser when you visit the Site, to become a user to the Service you will need to
                    create an account. You can create an account by registering with the Service and using one of the third party oAuth providers.
                </p>

                <h3>3. Children's Privacy</h3>
                <p>
                    The Site and the Service are not directed to anyone under the age of 21. The Site does not knowingly collect or solicit information from anyone under the age of 21,
                    or allow anyone under the age of 13 to sign up for the Service. In the event that we learn that we have gathered personal information from anyone under the age of 13
                    without the consent of a parent or guardian, we will delete that information as soon as possible. If you believe we have collected such information, please contact
                    us at hello@shadyhillstudios.com.
                </p>

                <h3>II. How We Use Informtion</h3>
                <p>
                    Personal Information:<br />
                    We do not sell, trade, rent or otherwise share for marketing purposes your Information with third parties without your consent.
                    We do share Information with vendors who are performing services for the Company, such as the servers for our email communications
                    who are provided access to user's email address for purposes of sending emails from us. Those vendors use your Information only at
                    our direction and in accordance with our Privacy Policy.
                </p>
                <p>
                    In general, the Information you provide to us is used to help us communicate with you. For example, we use Information to contact users
                    in response to questions, solicit feedback from users, and provide technical support.
                </p>
                <p>
                    We may share Information with outside parties if we have a good-faith belief that access, use, preservation or disclosure of the information
                    is reasonably necessary to meet any applicable legal process or enforceable governmental request; to enforce applicable Terms of Service,
                    including investigation of potential violations; address fraud, security or technical concerns; or to protect against harm to the rights,
                    property, or safety of our users or the public as required or permitted by law.
                </p>

                <p>
                    Non-Personal Information:<br />
                    In general, we use Non-Personal Information to help us improve the Service and customize the user experience.
                    We also aggregate Non-Personal Information in order to track trends and analyze use patterns on the Site.
                    This Privacy Policy does not limit in any way our use or disclosure of Non-Personal Information and we reserve the right to use
                    and disclose such Non-Personal Information to our partners, advertisers and other third parties at our discretion.
                </p>
                <p>
                    In the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our assets,
                    your Information may be among the assets transferred. You acknowledge and consent that such transfers may occur and are permitted by this
                    Privacy Policy, and that any acquirer of our assets may continue to process your Information as set forth in this Privacy Policy.
                    If our information practices change at any time in the future, we will post the policy changes to the Site so that you may opt out
                    of the new information practices. We suggest that you check the Site periodically if you are concerned about how your information is used.
                </p>


                <h3>III. How We Protect Information</h3>
                <p>
                    We implement security measures designed to protect your information from unauthorized access. Your account is protected by your registration data
                    and we urge you to take steps to keep your personal information safe by not disclosing your password and by logging out of your account after each use.
                    We further protect your information from potential security breaches by implementing certain technological security measures including encryption,
                    firewalls and secure socket layer technology. However, these measures do not guarantee that your information will not be accessed, disclosed,
                    altered or destroyed by breach of such firewalls and secure server software. By using our Service, you acknowledge that you understand and agree to assume
                    these risks.
                </p>

                <h3>IV. Your Rights Regarding the Use of Your Information</h3>
                <p>
                    You have the right at any time to prevent us from contacting you for marketing purposes.
                    Please note that notwithstanding the promotional preferences you indicate by unsubscribing, we may continue to send you administrative emails including,
                    for example, periodic updates to our Privacy Policy.
                </p>

                <h3>V. Links to Other Websites</h3>
                <p>
                    As part of the Service, we may provide links to or compatibility with other websites or applications.
                    However, we are not responsible for the privacy practices employed by those websites or the information or content they contain.
                    This Privacy Policy applies solely to information collected by us through the Site and the Service.
                    Therefore, this Privacy Policy does not apply to your use of a third party website accessed by selecting a link on our Site or via our Service.
                    To the extent that you access or use the Service through or on another website or application, then the privacy policy of that other website or
                    application will apply to your access or use of that site or application. We encourage our users to read the privacy statements of other websites
                    before proceeding to use them.
                </p>

                <h3>VI. Changes to Our Privacy Policy</h3>
                <p>
                    The Company reserves the right to change this policy at any time. We will notify you of significant changes to our Privacy Policy by sending a notice
                    to the primary email address specified in your account or by placing a prominent notice on our site. Significant changes will go into effect 30 days
                    following such notification. Non-material changes or clarifications will take effect immediately. You should periodically check the Site and this privacy page
                    for updates.
                </p>

                <h3>VII. Contact Us</h3>
                <p>
                    If you have any questions regarding this Privacy Policy or the practices of this Site, please contact us by sending an email to hello@shadyhillstudios.com.
                    Last Updated: This Privacy Policy was last updated on January 2, 2023.
                </p>
            </div>
        )
    }
}

export default Privacy
