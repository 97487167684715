import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchApiDataIfNeeded } from '../../../actions/api'
import { routes, apiResourceEndpoint } from '../../../constants/routes'

import { handleSignOut } from '../../../actions/auth'
// import { GOALS } from '../../../constants/goals'

class SettingsHome extends React.Component {
    state = {}

    componentDidMount = () => {
        if (this.props.auth.has('hasAuthId') && this.props.auth.get('hasAuthId')) {
            this.props.dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('setting', 'LIST')))
        }
    }

    componentDidUpdate = (prevProps) => {
        if ((!prevProps.auth.has('hasAuthId') || !prevProps.auth.get('hasAuthId')) && this.props.auth.has('hasAuthId') && this.props.auth.get('hasAuthId')) {
            this.props.dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('setting', 'LIST')))
        }
    }

    renderGoal = () => {
        let goal = this.props.settings.getIn(['_computed', 'goalDisplay'])
        if (!goal) {
            return null
        }
        return (
            <Link to={routes.settingsSetGoal} className="item">
                <div className="description">
                    <div className="title">
                        Goal: {goal}
                    </div>
                </div>
            </Link>
        )
    }

    renderTimer = () => {
        return (
            <Link to={routes.settingsReminder} className="item">
                <div className="description">
                    <div className="title">
                        Reminder: {this.props.settings.get('reminder') ? this.props.settings.get('reminderTime') : 'Not Set'}
                    </div>
                </div>
            </Link>
        )
    }

    renderVersion = () => {
        return (
            <Link to={routes.settingsPremium} className="item">
                <div className="description">
                    <div className="title">
                        Version: {this.props.settings.get('isLimited') ? 'Limited to 31 Days' : 'Premium' }
                    </div>
                </div>
            </Link>
        )
    }

    renderLoginLogout = () => {
        return (
            <div className="item">
                <div className="description">
                    {
                        this.props.auth.has('hasAuthId') && this.props.auth.get('hasAuthId') ?
                            <div className="title" onClick={() => this.props.dispatch(handleSignOut())}>
                                Logout
                            </div> :
                            <Link to={routes.settingsRegister} className="title">
                                Login
                            </Link>
                    }
                </div>
            </div>
        )
    }

    renderAbout = () => {
        return (
            <Link to={routes.settingsAbout} className="item">
                <div className="description">
                    <div className="title">
                        About
                    </div>
                </div>
            </Link>
        )
    }

    render = () => {
        const hasAuth = this.props.auth && this.props.auth.get('hasAuthId') ? this.props.auth.get('hasAuthId') : false
        return (
            <div className="onboard">
                <div className="options">
                    {this.renderGoal()}
                    {/* { hasAuth && this.renderTimer()} */}
                    { hasAuth && this.renderVersion()}
                    {this.renderLoginLogout()}
                    {this.renderAbout()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.get('auth'),
        settings: state.get('settings')
    }
}

export default connect(mapStateToProps)(SettingsHome)
