import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

class PaymentReceipt extends React.Component {
    state = {}

    componentDidMount = () => {
        if (this.props.auth.has('hasAuthId') && this.props.auth.get('hasAuthId')) {
            this.props.dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('payment', 'LIST')))
        }
    }

    render = () => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })
        return (
            <div className="onboard">
                <div className="options">
                    <div className="item">
                        <div className="description">
                            <div className="title">
                                Payment Receipt<br />
                                {this.props.payment && formatter.format(this.props.payment.get('amount') / 100)}<br />
                                {this.props.payment && moment(this.props.payment.get('createdAt')).format('dddd MMMM D, Y')}<br />
                                {this.props.payment && <span>{this.props.payment.get('paymentId')}</span>}<br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.get('auth'),
        payment: state.get('payment')
    }
}

export default connect(mapStateToProps)(PaymentReceipt)
