import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js'

import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'

import { upsertForm } from '../../../actions/form'
import { callApi } from '../../../actions/api'
import { formStates } from '../../../constants/helper-states'
import { apiResourceEndpoint, routes } from '../../../constants/routes'
import { manageApiData } from '../../../actions/local-api'

class PaymentForm extends React.Component {
    state = {
        shouldNavigate: false
    }

    submitFX = async (formData, formId) => {
        const { stripe, elements } = this.props

        // if we don't have the stripe pieces, can't really do anything...
        if (!stripe || !elements) {
            return
        }

        const cardElement = elements.getElement(CardElement)

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: formData.name_on_card
            }
        })

        if (error) {
            console.log('[error]', error)
            this.props.dispatch(upsertForm(formId, formStates.ERROR, error.message))
        } else {
            this.props.dispatch(callApi(apiResourceEndpoint('payment', 'CREATE'), {
                name: formData.name_on_card,
                paymentToken: paymentMethod.id
            }, formId))
        }
    }

    onHandleCancel = () => {
        this.props.dispatch(manageApiData({
            limited: true,
            onboardComplete: true,
            _key: 'SETTINGS'
        }))
        this.setState({ shouldNavigate: true })
        // this.props.onAdvancePaymentStep()
    }

    successFX = (paymentId) => {
        // if the purchase was made, then we can advance the step
        this.props.dispatch(manageApiData({
            purchaseId: paymentId,
            limited: false,
            onboardComplete: true,
            _key: 'SETTINGS'
        }))
        this.setState({ shouldNavigate: true })
        // this.props.onAdvancePaymentStep()
    }

    render = () => {
        if (this.state.shouldNavigate) {
            if (this.props.fromSettings) {
                return <Navigate to={routes.settingsIndex} />
            }
            return <Navigate to={routes.today} />
        }
        const { stripe } = this.props
        const cardElementOptions = {
            style: {
                base: {
                    color: '#FFF',
                    fontSize: '1.5rem'
                },
                invalid: {
                    color: '#fa755a'
                }
            }
        }

        return (
            <SHSForm
                submitFX={this.submitFX}
                successFX={this.successFX}
                buttonDisabled={!stripe ? true : false}
                showCancel={true}
                cancelAction={this.onHandleCancel}>
                <InputElement
                    type="text"
                    id="name_on_card"
                    label="Name on Card"
                    default={this.props.nameOnCard}
                    placeholder="Enter name as it appears on card"
                    required />
                <CardElement className="w-100 stripe-form" options={cardElementOptions} />
            </SHSForm>
        )
    }
}

const StripeCheckoutForm = (props) => {
    return (
        <ElementsConsumer>
            {({ elements, stripe }) => (
                <PaymentForm elements={elements} stripe={stripe} onSetPaymentInfo={props.onSetPaymentInfo} {...props} />
            )}
        </ElementsConsumer>
    )
}

export default connect()(StripeCheckoutForm)
