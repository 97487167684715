import React from 'react'
// import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js'
// import 'draft-js/dist/Draft.css'

import InfoMark from './info-mark'
import * as MASKS from './input-masks'

import '../../assets/scss/input.scss'

class InputElement extends React.Component {
    state = {
        value: typeof this.props.default !== 'undefined' ? this.props.default : '',
        isActive: false,
        rawValue: '',
        tempValue: ''
    }

    componentDidMount = () => {
        if (this.props.isFormatted && this.props.formatMask) {
            const formattedValue = this.props.formatMask.resolve(String(this.state.value))
            const rawValue = this.props.formatMask.unmaskedValue
            this.setState({
                value: formattedValue,
                rawValue: rawValue
            })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.default !== this.props.default) {
            if (this.props.isFormatted && this.props.formatMask) {
                const formattedValue = this.props.formatMask.resolve(String(this.props.default))
                const rawValue = this.props.formatMask.unmaskedValue
                this.setState({
                    value: formattedValue,
                    rawValue: rawValue
                })
            } else if (this.props.type !== 'wysiwyg') {
                this.setState({ value: this.props.default })
            }
        }
        if (prevProps.formatMask !== this.props.formatMask && this.props.isFormatted) {
            const formattedValue = this.props.formatMask.resolve(String(this.state.value))
            const rawValue = this.props.formatMask.unmaskedValue
            this.setState({
                value: formattedValue,
                rawValue: rawValue
            })
        }
        // for masks that have a string added to the end, need to correct cursor position on first entry
        if (this.state.isActive && this.props.isFormatted && this.state.value.length === 2) {
            if (this.props.formatMask === MASKS.percentageMask) {
                let el = document.getElementById(this.props.id)
                el.setSelectionRange(1, 1)
            }
        }
    }

    renderInfo = () => {
        if (this.props.infoMark && this.props.infoMark !== '') {
            return <InfoMark info={this.props.infoMark} />
        }
        return null
    }

    renderLabel = (label) => {
        if (label === null) {
            return null
        }
        if (!label || label === '') {
            return <label className="form-label">&nbsp;{this.renderInfo()}</label>
        }
        return <label className="form-label">{label}{this.renderInfo()}</label>
    }

    handleChange = (evt) => {
        let value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value
        if (this.props.isFormatted && this.props.formatMask) {
            const formattedValue = this.props.formatMask.resolve(value)
            const rawValue = this.props.formatMask.unmaskedValue
            this.setState({
                value: formattedValue,
                rawValue: rawValue
            })
        } else {
            this.setState({ value: value })
        }
        // TODO: check if we need to send the value to a parent? probably would be raw value
        if (this.props.onChange) {
            this.props.onChange(evt.target.id, value)
        }
    }

    renderError = () => {
        return (
            <div className="invalid-feedback">
                {this.props.errorMessage || 'This field is required'}
            </div>
        )
    }

    renderHelperText = (helperText) => {
        if (this.props.calculatedDisplay && this.state.value !== '') {
            return null
        }
        return <small className={`form-text ${this.state.isActive ? 'visible' : 'invisible'}`}>{helperText}</small>
    }

    handleOptionClick = (option) => {
        if (this.state.value === option) {
            this.setState({ value: '' })
        } else {
            this.setState({ value: option })
        }
    }

    handleMultiOptionClick = (option) => {
        let values = this.state.value
        const idx = values.indexOf(option)
        if (idx === -1) {
            values.push(option)
        } else {
            values.splice(idx, 1)
        }
        this.setState({ value: values })
    }

    renderRangeSlider = (props, commonProps) => {
        return (
            <>
                {this.renderLabel(this.props.label)}
                <div>
                    <input
                        type="range"
                        value={this.state.value}
                        min={this.props.min}
                        max={this.props.max}
                        step={this.props.step}
                        onChange={(evt) => {
                            this.setState({ value: evt.target.value })
                            this.handleChange(evt)
                        }} />
                    {this.state.value}
                </div>
            </>
        )
    }

    renderButtonSelect = (props, commonProps) => {
        return (
            <>
                {this.renderLabel(this.props.label)}
                <div className="option-btns">
                    {this.props.options.map((option, idx) => {
                        return (
                            <span key={idx} className={`btn btn-select ${this.state.value === option.value ? 'active' : ''}`} onClick={() => this.handleOptionClick(option.value)}>
                                {option.label}
                            </span>
                        )
                    })}
                    <input
                        type="hidden"
                        id={commonProps.id}
                        name={commonProps.name}
                        value={this.state.value} />
                </div>
            </>
        )
    }

    renderButtonMultiSelect = (props, commonProps) => {
        return (
            <>
                {this.renderLabel(this.props.label)}
                <div className="option-btns">
                    {this.props.options.map((option, idx) => {
                        return (
                            <span key={idx} className={`btn btn-select ${this.state.value.indexOf(option.value) !== -1 ? 'active' : ''}`} onClick={() => this.handleMultiOptionClick(option.value)}>
                                {option.label}
                            </span>
                        )
                    })}
                    <input
                        type="hidden"
                        id={commonProps.id}
                        name={commonProps.name}
                        value={this.state.value} />
                </div>
            </>
        )
    }

    renderSelect = (props, commonProps) => {
        return (
            <>
                {this.renderLabel(this.props.label)}
                <select {...commonProps}>
                    { this.props.includeInitialValue !== false &&
                        <option value="">
                            {this.props.initialValueLabel || 'Select a value'}
                        </option>
                    }
                    {props.options.map((option, idx) => {
                        return <option value={option} key={idx}>{option}</option>
                    })}
                </select>
            </>
        )
    }

    renderValueSelect = (props, commonProps) => {
        return (
            <>
                {this.renderLabel(this.props.label)}
                <select {...commonProps} onClick={(evt) => evt.stopPropagation()}>
                    { this.props.includeInitialValue !== false &&
                        <option value="">
                            {this.props.initialValueLabel || 'Select a value'}
                        </option>
                    }
                    {props.options.map((option, idx) => {
                        return <option value={option.value} key={idx}>{option.label}</option>
                    })}
                </select>
                { this.props.helperText !== '' &&
                    <small className="helper-text">{this.props.helperText}</small>
                }
            </>
        )
    }

    setTempValue = (evt) => {
        this.setState({ tempValue: evt.target.value })
    }

    handleTempSave = (evt) => {
        if (evt.keyCode === 13) {
            evt.preventDefault()
            evt.stopPropagation()
            let { value } = this.state
            if (value !== '') {
                value = value + `|${evt.target.value}`
            } else {
                value = evt.target.value
            }
            this.setState({
                value,
                tempValue: ''
            })
        }
    }

    removeKeyword = (idx) => {
        let keywords = this.state.value.split('|')
        keywords.splice(idx, 1)
        this.setState({ value: keywords.join('|') })
    }

    renderKeywordList = (props, commonProps) => {
        let keywords = this.state.value.split('|')
        let allKeywords = []
        keywords.forEach((keyword, idx) => {
            if (keyword !== '') {
                allKeywords.push(
                    <div className="item" key={idx} onClick={() => this.removeKeyword(idx)}>
                        {keyword} <span className="icon-text">X</span>
                    </div>
                )
            }
        })
        return (
            <>
                {this.renderLabel(this.props.label)}
                <input
                    type="text"
                    id="tempKeyword"
                    className="form-control"
                    value={this.state.tempValue}
                    onChange={this.setTempValue}
                    onKeyDown={this.handleTempSave}
                    placeholder={this.props.placeholder} />
                <div className="selections left-align">{allKeywords}</div>
                <input
                    type="hidden"
                    id={commonProps.id}
                    name={commonProps.name}
                    value={this.state.value} />
            </>
        )
    }

    renderInput = (props, commonProps) => {
        const type = props.type || 'text'
        if (this.props.refName) {
            commonProps.ref = this.props.refName
        }
        if (type === 'checkbox') {
            // need to remove the value flag and just use checked
            delete commonProps.value
            return (
                <>
                    <input type={type} {...commonProps} checked={this.state.value} />
                    <label className="form-check-label">{this.props.label}</label>
                </>
            )
        }
        if (type === 'textarea') {
            return (
                <>
                    {this.renderLabel(this.props.label)}
                    <textarea
                        onClick={(evt) => evt.stopPropagation()}
                        readOnly={this.props.readOnly ? true : false}
                        onBlur={() => this.setState({ isActive: false })}
                        onFocus={() => this.setState({ isActive: true })}
                        autoComplete="off"
                        type={type}
                        {...commonProps} />
                </>
            )
        }
        if (type === 'file') {
            commonProps.className = 'form-control-file'
        }
        return (
            <>
                {this.renderLabel(this.props.label)}
                <input
                    onClick={(evt) => evt.stopPropagation()}
                    readOnly={this.props.readOnly ? true : false}
                    onBlur={() => this.setState({ isActive: false })}
                    onFocus={() => this.setState({ isActive: true })}
                    autoComplete="off"
                    type={type}
                    {...commonProps} />
            </>
        )
    }

    renderFormInput = (props) => {
        const commonProps = {
            value: this.state.value || '',
            rawvalue: this.state.rawValue,
            id: props.id,
            name: props.id,
            onChange: this.handleChange,
            className: props.type === 'checkbox' ? 'form-check-input' : 'form-control',
            placeholder: props.placeholder,
            required: props.required || false,
            disabled: props.disabled || false
        }
        switch (props.type) {
        case 'range':
            return this.renderRangeSlider(props, commonProps)
        case 'buttonSelect':
            return this.renderButtonSelect(props, commonProps)
        case 'buttonMultiSelect':
            return this.renderButtonMultiSelect(props, commonProps)
        case 'select':
            return this.renderSelect(props, commonProps)
        case 'valueSelect':
            return this.renderValueSelect(props, commonProps)
        case 'keywordList':
            return this.renderKeywordList(props, commonProps)
        // case 'wysiwyg':
        //     return this.renderWYSIWYG(props, commonProps)
        default:
            return this.renderInput(props, commonProps)
        }
    }

    renderCalculatedDisplay = (props) => {
        if (this.props.calculatedDisplay) {
            if (this.state.value !== '') {
                return (
                    <div className="calculated">
                        {this.props.calculatedDisplay(this.state.value)}
                    </div>
                )
            }
        }
        return null
    }

    render = () => {
        return (
            // <div className={`display-input ${this.props.extraClass || ''}`}>
            //     <div className={`form-group ${this.props.type === 'checkbox' ? 'form-check' : ''}`}>
            <div className="mb-3">
                {this.renderFormInput(this.props)}
                {this.renderCalculatedDisplay(this.props)}
                {this.renderError(this.props.errorMessage)}
                {this.renderHelperText(this.props.helperText)}
            </div>
        )
    }
}

export default InputElement
