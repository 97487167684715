import React from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'
import moment from 'moment'

import { callApi } from '../../../actions/api'
import { fillDates } from '../../../actions/local-api'

import glassX from '../../../assets/imgs/icon-glass-x-01.svg'
import glassFull from '../../../assets/imgs/icon-glass-full-01.svg'

// import StreakCounter from '../reports/streak'
import { apiResourceEndpoint } from '../../../constants/routes'

import DrinkCounter from './drink-counter'

class DayView extends React.Component {
    state = {
        isComplete: this.props.day && this.props.day.get('wasSober') !== null ? true : false,
        wasSober: this.props.day ? this.props.day.get('wasSober') : null,
        inEdit: false,
        isMoving: false,
        fillPos: null,
        showDrinkCounter: false,
        drinksCount: 0
    }

    componentDidMount = () => {
        const { day } = this.props
        if (!day) {
            this.props.dispatch(fillDates(day))
        }
        // need to set the top position of the fill to smooth the animation later
        // TODO: Test in emulator
        if (this.refs.quizBtnSlider) {
            let fillPos = this.refs.quizBtnSlider.getBoundingClientRect().top
            this.setState({ fillPos })
            this.refs.drinkFill.style.top = fillPos + 'px'
        }
    }

    componentDidUpdate = (prevProps) => {
        if ((!prevProps.day && this.props.day) || (this.props.day !== prevProps.day)) {
            this.setState({
                isComplete: this.props.day.get('wasSober') === null ? false : true,
                wasSober: this.props.day.get('wasSober')
            })
        }
    }

    // val is true for sober, false for drank
    setQuiz = (val) => {
        this.setState({ wasSober: val })
        if (val === true) {
            setTimeout(this.recordLog, 500)
        } else {
            setTimeout(() => this.setState({ showDrinkCounter: true }), 500)
        }
    }

    recordLog = () => {
        let dayData = this.props.day.toJSON()
        dayData.wasSober = this.state.wasSober
        dayData.drinksCount = this.state.drinksCount
        dayData._key = 'HISTORIES'
        this.props.dispatch(callApi(apiResourceEndpoint('historie', 'CREATE'), dayData))
        this.setState({ inEdit: false })
        // if (this.state.wasSober) {
        //     this.setState({ inEdit: false })
        // } else {
        //     this.setState({ showDrinkCounter: true })
        // }
    }

    renderComplete = () => {
        const { day } = this.props
        // const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD')
        let drank = ''
        let icon = null
        if (day.get('wasSober')) {
            icon = <img src={glassX} alt="Sober" />
            drank = <span>you did not have a drink</span>
        } else {
            icon = <img src={glassFull} alt="Drank" />
            if (day.get('drinksCount') > 1) {
                drank = <span>you had {day.get('drinksCount')} drinks</span>
            } else {
                drank = <span>you had a drink</span>
            }
        }
        return (
            <div className={`completed ${day.get('wasSober') ? '' : 'drank'}`}>
                <div className="drank-status">
                    { icon }
                    <span className="date">
                        On {moment(this.props.day.get('date')).format('dddd')}<br/>
                        {moment(this.props.day.get('date')).format('MMMM D, Y')}
                    </span>
                    <span className="result">{drank}</span>
                    <span className="alter" onClick={() => this.setState({ inEdit: true, wasSober: null })}>
                        Edit Record
                    </span>
                </div>
            </div>
        )
    }

    setDrinkCount = (count) => {
        // TODO: Set state on ineraction with quiz TBD
        this.setState({ drinksCount: count })
    }

    renderDrinksCount = () => {
        let allDrinks = []
        for (let d = 1; d <= 6; d = d + 1) {
            allDrinks.push(
                <DrinkCounter key={d} number={d} activeCount={this.state.drinksCount} onSetDrinkCount={this.setDrinkCount} />
            )
        }
        return (
            <>
                <div className="counter-holder">
                    {allDrinks}
                </div>
                <div
                    onClick={this.recordLog}
                    className="quiz-submit">
                    Save
                </div>
            </>
        )
    }

    renderQuiz = () => {
        let fillUp = null
        if (this.state.wasSober !== null && this.state.wasSober) {
            fillUp = 'no'
        } else if (this.state.wasSober !== null) {
            fillUp = 'yes'
        }
        return (
            <div className="quiz">
                {
                    this.state.showDrinkCounter ?
                        <>
                            <h1>How many drinks did you have?</h1>
                            {this.renderDrinksCount()}
                            <div id="drink-fill-wrapper" ref="quizBtnSlider" className="yes">
                                <div ref="drinkFill" className="drink-fill" />
                            </div>
                        </> :
                        <>
                            <h1>
                                Did you drink alcohol&nbsp;
                                <span className="date-display">
                                    {
                                        this.props.dateStamp === moment().subtract(1, 'days').format('YYYY-MM-DD') ?
                                            'yesterday' :
                                            <>on {moment(this.props.day.get('date')).format('dddd MMMM D')}</>
                                    }
                                </span>
                                ?
                            </h1>
                            <div className={`quiz-btn-wrapper ${this.state.wasSober !== null ? 'selected' : ''}`}>
                                <div
                                    className={`label ${this.state.wasSober === false ? 'active' : ''}`}
                                    onClick={() => this.setQuiz(false)}>
                                    Yes
                                </div>
                                <div
                                    className={`label ${this.state.wasSober === true ? 'active' : ''}`}
                                    onClick={() => this.setQuiz(true)}>
                                    No
                                </div>
                            </div>
                            <div id="drink-fill-wrapper" ref="quizBtnSlider" className={fillUp}>
                                <div ref="drinkFill" className="drink-fill" />
                            </div>
                        </>
                }
            </div>
        )
    }

    render = () => {
        const { day } = this.props
        if (!day) {
            return null
        }
        return (
            <div className="day-view">
                {
                    this.state.isComplete && !this.state.inEdit ?
                        this.renderComplete() :
                        this.renderQuiz()
                }
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let dateStamp = ownProps.params.dayDate
    if (!dateStamp) {
        dateStamp = moment().subtract(1, 'days').format('YYYY-MM-DD')
    }
    const curDay = moment(dateStamp).format('YYYY-MM-DD')
    return {
        day: state.get('dates').find(d => d.get('date') === curDay),
        dateStamp
    }
}

export default withRouterProps(connect(mapStateToProps)(DayView))
