import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { callApi } from '../../../actions/api'
import { apiResourceEndpoint, routes } from '../../../constants/routes'

import SHSForm from '../../utils/shs-form'

class ReminderSetting extends React.Component {
    state = {
        time: this.props.settings && this.props.settings.get('reminderTime') ? this.props.settings.get('reminderTime') : '10:00',
        shouldNavigate: false
    }

    componentDidMount = () => {
        console.log('in the reminder settings')
        console.log(this.props)
    }

    handleTimeChange = (evt) => {
        this.setState({ time: evt.target.value })
    }

    submitFX = (formData, formId) => {
        const objData = {
            reminder: true,
            reminderTime: this.state.time,
            _key: 'SETTINGS'
        }
        this.props.dispatch(callApi(apiResourceEndpoint('setting', 'CREATE'), objData, formId))
    }

    successFX = () => {
        this.setState({ shouldNavigate: true })
    }

    renderNextBtn = () => {
        return (
            <>
                <SHSForm
                    submitFX={this.submitFX}
                    successFX={this.successFX}
                    buttonLabel={this.state.time ? 'Save Time' : 'Set a Time'}
                    buttonDisabled={this.state.time ? false : true} />
                { !this.props.fromSettings &&
                    <span className="clickable skip-link" onClick={() => this.setState({ shouldNavigate: true })}>
                        Don't set a reminder
                    </span>
                }
            </>
        )
    }

    render = () => {
        if (this.state.shouldNavigate) {
            if (this.props.fromSettings) {
                return <Navigate to={routes.settingsIndex} />
            }
            return <Navigate to={routes.onboardPremium} />
        }
        return (
            <div className="onboard">
                <h1>Set Daily Reminder?</h1>
                <div className="prompt">
                    <p>The app can prompt you every day to ask if you drank or not. You can always change this later</p>
                </div>
                <div className="form-input">
                    <input onChange={this.handleTimeChange} type="time" id="time" value={this.state.time ? this.state.time.substr(0, 5) : ''} />
                </div>
                {this.renderNextBtn()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        settings: state.get('settings')
    }
}

export default connect(mapStateToProps)(ReminderSetting)
