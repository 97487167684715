import { combineReducers } from 'redux-immutable'
import { fromJS } from 'immutable'

import apiCacheReducer from './api-cache'
import authCodeReducer from './auth-code'
import authReducer from './auth'
import formReducer from './form'
import genericReducer from './generic'
import shortid from 'shortid'
// import settingsReducer from './settings'

const BLANK_OBJ = fromJS({})
const BLANK_LIST = fromJS([])

const INITIAL_SETTINGS = fromJS({
    guid: shortid(),
    goal: null,
    reminder: null,
    reminderTime: null,
    purchaseId: null,
    limited: true,
    onboardComplete: false,
    shareAnonymous: null
})

const indexReducer = combineReducers({
    apiCaches: apiCacheReducer,
    auth: authReducer,
    authCode: authCodeReducer,
    calculations: genericReducer('CALCULATIONS', 'OBJECT', BLANK_OBJ),
    dates: genericReducer('HISTORIES', 'LIST', BLANK_LIST, 'date'),
    forms: formReducer,
    payment: genericReducer('PAYMENT', 'OBJECT', BLANK_OBJ),
    settings: genericReducer('SETTINGS', 'OBJECT', INITIAL_SETTINGS),
    stats: genericReducer('STATS', 'OBJECT', BLANK_OBJ),
    user: genericReducer('USER', 'OBJECT', BLANK_OBJ)
})

export default indexReducer
