import moment from 'moment'

export function whereToGo(dates, settings, user, auth) {
    let nextLocation = 'ONBOARD'
    if (!auth.has('hasAuthId') || !auth.get('hasAuthId')) {
        // nextLocation = 'WELCOME'
        // they are logged out, so next location stays at onboard
    } else if (settings.get('onboardComplete')) {
        const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD')
        const activeDay = dates.find(d => d.get('date') === yesterday)
        if (!activeDay) {
            alert('data is missing, please reload')
        } else if (activeDay.get('wasSober') === null) {
            nextLocation = 'TODAY'
        } else {
            nextLocation = 'REPORTS'
            // nextLocation = 'GOALS'
        }
    } else if (!settings.get('goal')) {
        nextLocation = 'GOALS'
    } else if (!settings.get('purchaseId')) {
        nextLocation = 'PURCHASE'
    }
    return nextLocation
}
