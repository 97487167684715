import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import iconHalf from '../../../assets/imgs/icons/dryspell-icon-half.svg'
import iconX from '../../../assets/imgs/icons/dryspell-icon-x.svg'

class WeekendWarrior extends React.Component {
    state = {}

    renderGraphDays = () => {
        let { goals } = this.props
        let curWeek = moment().subtract(1, 'days').format('YYYY-w')
        let week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday']
        let days = []
        if (!goals || !goals[curWeek]) {
            return null
        }
        Object.keys(goals[curWeek]).forEach(d => {
            if (week.indexOf(d) > -1) {
                if (goals[curWeek][d] === null) {
                    days.push(<div key={d} className="day" />)
                } else if (goals[curWeek][d]) {
                    days.push(<div key={d} className="day white icon-r180"><img src={iconX} alt="dry spell" /></div>)
                } else {
                    days.push(<div key={d} className="day white"><img src={iconHalf} className="filter-orange" alt="dry spell" /></div>)
                }
            }
        })
        return days
    }

    daysTilWeekend = () => {
        let today = moment().isoWeekday()
        let diff = 4 - today
        if (diff > 0) {
            return (
                <>
                    <div className="value">{ diff }</div>
                    <div className="label">days til weekend</div>
                </>
            )
        }
        return (
            <>
                <div className="value" />
                <div className="label">It's the Weekend!</div>
            </>
        )
    }

    render = () => {
        return (
            <div className="goals-wrapper">
                <div className="status">
                    { this.daysTilWeekend() }
                    <div className="graph glasses">
                        { this.renderGraphDays() }
                    </div>
                </div>
                <div className="badges">
                    {/* Sample Badges */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        goals: state.getIn(['calculations', 'goals', 'weekendWarrior'])
    }
}

export default connect(mapStateToProps)(WeekendWarrior)
