import IMask from 'imask'

export const integerMask = IMask.createMask({
    mask: Number,
    scale: 2,
    signed: false,
    thousandsSeparator: ',',
    normalizeZeros: true,
    radix: '.'
})

export const dollarMask = IMask.createMask({
    mask: '$amount',
    blocks: {
        amount: {
            mask: Number,
            scale: 2,
            signed: true,
            thousandsSeparator: ',',
            normalizeZeros: false,
            radix: '.'
        }
    }
})

export const phoneMask = IMask.createMask({
    mask: '(000) 000-0000'
})

export const postalMask = IMask.createMask({
    mask: '*****-****'
})

export const percentageMask = IMask.createMask({
    mask: [
        { mask: '' },
        {
            mask: 'num%',
            lazy: false,
            blocks: {
                num: {
                    mask: Number,
                    scale: 2,
                    min: -100,
                    max: 100,
                    radix: '.',
                    normalizeZeros: false,
                    mapToRadix: [',']
                }
            }
        }
    ]
})

export const displayPercentageMask = IMask.createMask({
    mask: [
        { mask: '' },
        {
            mask: 'num%',
            lazy: false,
            blocks: {
                num: {
                    mask: Number,
                    scale: 2,
                    radix: '.',
                    normalizeZeros: false
                }
            }
        }
    ]
})
