import { generatePath } from 'react-router-dom'

export const API_METHODS = { POST: 'POST', GET: 'GET', PUT: 'PUT', DELETE: 'DELETE' }

// thoughts on routes
// cheers/ –  welcome route
// onboard/ - prompt to install the app
// onboard/set-goal - set your goal
// onboard/register - set an account
// onboard/reminder - set a reminder
// onboard/premium - show premium offer
// onboard/payment - purchase
// today/ - view of today (if no record for today)
// calendar/ - view of all dates
// calendar/:dayDate - view of date (return to calendar?)
// reports/ - view of current status
// reports/overall
// reports/by-day
// reports/drinks
// reports/monthly
// settings - overview
// settings/pages...

export const routes = {
    index: '/',
    router: '/router/',

    welcome: '/cheers/',

    // dayIndex: '/days/',
    // dayView: '/days/:dayDate',
    // dayEdit: '/days/:dayDate/edit',

    today: '/today/',
    calendarIndex: '/calendar/',
    calendarDay: '/calendar/:dayDate/',

    reportsIndex: '/reports/',
    reportsWeekly: '/reports/weekly/',
    reportsMonthly: '/reports/monthly/',
    reportsStats: '/reports/statistics/',

    goalsIndex: '/goals/',
    goalsDrySpell: '/goals/dry-spell/',
    goalsHalfFull: '/goals/half-full/',
    goalsModerator: '/goals/moderator/',
    goalsWeekendWarrior: '/goals/weekend-warrior',

    settingsIndex: '/settings/',
    settingsSetGoal: '/settings/set-goal/',
    settingsReminder: '/settings/reminder/',
    settingsRegister: '/settings/register/',
    settingsPremium: '/settings/premium/',
    settingsPayment: '/settings/payment/',
    settingsReceipt: '/settings/receipt/',
    settingsAbout: '/settings/about/',

    onboardIndex: '/onboard/',
    onboardSetGoal: '/onboard/set-goal/',
    onboardRegister: '/onboard/register/',
    onboardReminder: '/onboard/reminder/',
    onboardPremium: '/onboard/premium/',
    onboardPayment: '/onboard/payment/',
    // onboardPaymentConfirmation: '/onboard/payment-confirmation/',

    authLogin: '/login/',
    authLogout: '/logout/',
    authRegister: '/register/',
    authCreateUser: '/create-user/',
    authCreateAccount: '/create-account/',
    authPasswordHelp: '/password-help/',
    authPasswordReset: '/password-reset/',
    authHandler: '/auth-handler/',
    privacyPolicy: '/privacy-policy/',
    termsOfUse: '/terms-of-use/',

    appIndex: '/app/'
}

export const nestedRoutePath = (parent, route, isParent = false) => {
    let nestedRoute = route.substr(parent.length)
    if (isParent) {
        nestedRoute = nestedRoute + '*'
    }
    return nestedRoute
}

export const apiResourceEndpoint = (resource, action, id = null, extraPath = '') => {
    let routeObj = {
        url: `/${resource}s/`,
        method: API_METHODS.GET
    }
    // adjust the route
    if (action === 'UPDATE' || action === 'VIEW' || action === 'DELETE') {
        routeObj.url = routeObj.url + `${id}/`
    }
    // adjust the medthod
    if (action === 'CREATE' || action === 'UPDATE') {
        routeObj.method = API_METHODS.POST
    } else if (action === 'DELETE') {
        routeObj.method = API_METHODS.DELETE
    }
    // do we need to append any extra stuff to the url
    if (extraPath !== '') {
        routeObj.url = routeObj.url + extraPath + '/'
    }
    return routeObj
}

export const apiRoutes = {
    webGet: { url: '/', method: API_METHODS.GET }
}

export const buildRoutePath = (route, params = {}) => {
    return generatePath(route, params)
}

export const buildModalPath = (route, fullPagePath, params = {}) => {
    const modal = '/m/'
    const modalIndex = fullPagePath.indexOf(modal)
    let pagePath = fullPagePath
    if (modalIndex !== -1) {
        pagePath = fullPagePath.substr(0, modalIndex + 1)
    }
    let modalRoute = route.substr(route.indexOf(modal) + 1)
    const generatedPath = generatePath(modalRoute, params)
    return `${pagePath}${generatedPath}${window.location.search}`
}

export const buildCloseModalPath = (fullPagePath) => {
    const modal = '/m/'
    const modalIndex = fullPagePath.indexOf(modal)
    let pagePath = fullPagePath
    if (modalIndex !== -1) {
        pagePath = fullPagePath.substr(0, modalIndex + 1)
    }
    return pagePath
}
