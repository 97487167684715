import 'react-app-polyfill/stable'
import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { fromJS } from 'immutable'
import configureStore from './store/configure-store'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

import { initFirebase } from './firebase/firebase'

import './assets/scss/main.scss'
import App from './components/app'

const firebaseApp = initFirebase()

let persistedState = localStorage.getItem('moresober') ? JSON.parse(localStorage.getItem('moresober')) : {}
let store = configureStore(fromJS(persistedState))

store.subscribe(() => {
    localStorage.setItem('moresober', JSON.stringify(store.getState()))
})

render(
    <Provider store={store}>
        <BrowserRouter>
            <App firebaseApp={firebaseApp}/>
        </BrowserRouter>
    </Provider>, document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
