import React from 'react'
import { connect } from 'react-redux'

class DrySpell extends React.Component {
    state = {}

    renderMain = () => {
        let { dryStreak } = this.props
        return (
            <>
                <div className="value">{ dryStreak }</div>
                <div className="label">days dry</div>
            </>
        )
    }

    render = () => {
        return (
            <div className="goals-wrapper">
                <div className="status">
                    { this.renderMain() }
                </div>
                <div className="badges">
                    {/* Sample Badges */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        dryStreak: state.getIn(['calculations', 'dryStreak'])
    }
}

export default connect(mapStateToProps)(DrySpell)
