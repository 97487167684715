import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import ndaokData from '../reducers/index'
import { composeWithDevTools } from 'remote-redux-devtools'

const composeEnhancers = composeWithDevTools({ name: 'Dry Spell State', realtime: true, port: 8000 })
export default function configureStore(initialState) {
    return createStore(
        ndaokData,
        initialState,
        composeEnhancers(
            applyMiddleware(thunk)
        )
    )
}
