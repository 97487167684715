import React from 'react'
// import { Link } from 'react-router-dom'

// import { routes } from '../../constants/routes'

import Logo from '../../assets/imgs/dryspell-logo-text-01.svg'

class AppHeader extends React.Component {
    state = {}

    render = () => {
        return (
            <div className="header">
                {/* <Link to={routes.index}> */}
                <img src={Logo} alt="Dry Spell" />
                {/* </Link> */}
            </div>
        )
    }
}

export default AppHeader
