import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import StripeCheckoutForm from './payment-form'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

class PaymentStep extends React.Component {
    state = {
        stripe: null
    }

    render = () => {
        return (
            <Elements stripe={stripePromise}>
                <div className="onboard">
                    <StripeCheckoutForm {...this.props} />
                </div>
            </Elements>
        )
    }
}

export default PaymentStep
