// import { getAuth } from 'firebase/auth'
// import { API_METHODS, buildRoutePath } from '../constants/routes'
// import { formStates } from '../constants/helper-states'
// import { upsertForm } from './form'

import isEqual from 'lodash/isEqual'

import moment from 'moment'

// const API_URL = process.env.REACT_APP_API_URL

// const FIRST_DATE = '2022-01-01'

const apiAction = (type, data) => {
    return { type, data }
}

const apiListAction = (data) => {
    if (data.length > 0 && '_key' in data[0]) {
        return { type: `SAVE_${data[0]._key}_LIST`, data }
    }
    return { type: null }
}

const apiSingleAction = (data) => {
    if (data && '_key' in data) {
        return { type: `SAVE_${data._key}`, data }
    }
    return { type: null }
}

const checkForObjEquality = (data, updateObj) => {
    if (!('_key' in data)) {
        return updateObj
    }
    let key = data._key
    let dataCopy = { ...data }
    let objCopy = { ...updateObj }
    delete dataCopy._related
    if (key in objCopy) {
        let match = false
        objCopy[key].forEach(keyData => {
            if (match) {
                return
            }
            // remove the related from the objs
            if (isEqual(keyData, dataCopy)) {
                // console.log('saving a match')
                match = true
                return
            }
        })
        if (!match) {
            objCopy[key].push(dataCopy)
        }
    } else {
        objCopy[key] = [dataCopy]
    }
    return objCopy
}

export const fillDates = () => {
    return (dispatch, getState) => {
        let newDates = []
        let yesterday = moment().subtract(1, 'days')
        // let firstDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
        let firstDate = moment().startOf('month').format('YYYY-MM-DD')
        const allDates = getState().get('dates').sort((a, b) => {
            if (a.get('date') > b.get('date')) {
                return 1
            }
            return -1
        })
        if (allDates.size > 0 && allDates.first().get('date') < firstDate) {
            firstDate = moment(allDates.first().get('date'))
        }
        const dayCount = yesterday.diff(firstDate, 'd')
        for (let dc = dayCount; dc >= 0; dc = dc - 1) {
            let activeMoment = moment().subtract(1, 'days').subtract(dayCount - dc, 'days')
            let query = activeMoment.format('YYYY-MM-DD')
            let exists = allDates.find(d => d.get('date') === query)
            if (!exists) {
                newDates.push({
                    date: query,
                    offset: dc,
                    wasSober: null,
                    drinksCount: 0,
                    year: activeMoment.year(),
                    month: activeMoment.month(),
                    day: activeMoment.day(),
                    week: activeMoment.week(),
                    weekday: activeMoment.weekday(),
                    isWeeknight: activeMoment.weekday() === 5 || activeMoment.weekday() === 6 ? false : true,
                    _key: 'HISTORIES'
                })
            } else {
                newDates.push({
                    date: query,
                    week: activeMoment.week(),
                    _key: 'HISTORIES'
                })
            }
        }
        dispatch(manageApiData(newDates))
    }
}

export const manageApiData = (data, updateObj = {}, depth = 0) => {
    let uObj = { ...updateObj }
    if (!data || (Array.isArray(data) && data.length === 0)) {
        if (depth === 0) {
            return dispatch => {
                return { type: null }
            }
        }
        return updateObj
    } else if (Array.isArray(data)) {
        data.forEach(item => {
            if (item && '_related' in item) {
                Object.keys(item._related).forEach(relation => {
                    let relatedObj = manageApiData(item._related[relation], uObj, depth + 1)
                    uObj = { ...relatedObj }
                })
            }
            let myObj = checkForObjEquality(item, uObj)
            uObj = { ...myObj }
        })
        if (depth > 0) {
            return uObj
        }
    } else {
        if ('_related' in data) {
            Object.keys(data._related).forEach(relation => {
                let relatedObj = manageApiData(data._related[relation], uObj, depth + 1)
                uObj = { ...relatedObj }
            })
        }
        let myObj = checkForObjEquality(data, uObj)
        uObj = { ...myObj }
        if (depth > 0) {
            return uObj
        }
    }
    if (depth === 0) {
        return dispatch => {
            Object.keys(uObj).forEach(updateKey => {
                // console.log('calling dispatch')
                if (uObj[updateKey].length === 1) {
                    dispatch(apiSingleAction(uObj[updateKey][0]))
                } else {
                    dispatch(apiListAction(uObj[updateKey]))
                }
            })
        }
    }
}

export const fakeActionCall = (action, data) => {
    return dispatch => {
        dispatch(apiAction(action, data))
    }
}
