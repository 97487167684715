import React from 'react'
import { connect } from 'react-redux'

import iconHalf from '../../../assets/imgs/icons/dryspell-icon-half.svg'
import iconX from '../../../assets/imgs/icons/dryspell-icon-x.svg'

class HalfFull extends React.Component {
    state = {}

    renderMain = () => {
        let { goals } = this.props
        if (!goals) {
            // TODO: Add text to click the menu item or add button
            return <div className="label">No Info Yet. Go add some days!</div>
        }
        let label = 'Perfectly Balanced!'
        let percent = 50
        if (goals.delta !== 0) {
            if (goals.drunkDays > goals.soberDays) {
                label = `+${Math.abs(goals.delta)} days drinking`
            } else {
                label = `+${goals.delta} days sober`
            }
            if (goals.drunkDays > 0) {
                percent = Math.floor(goals.drunkDays / (goals.drunkDays + goals.soberDays) * 100)
            } else {
                percent = 0
            }
        }
        return (
            <>
                <div className="value">{ percent }%</div>
                <div className="label">{ label }</div>
                <div className="graph half-full">
                    <img src={iconX} alt="dry spell icon" className="icon-r180" />
                    <div className="graph-wrapper">
                        <div className="graph-fill" style={{ width: `${percent}%` }} />
                    </div>
                    <img src={iconHalf} alt="half full goal" className="filter-orange" />
                </div>
            </>
        )
    }

    render = () => {
        return (
            <div className="goals-wrapper">
                <div className="status">
                    { this.renderMain() }
                </div>
                <div className="badges">
                    {/* Sample Badges */}
                    {/* <div className="badge">
                        <div>
                            <div>123</div>
                            <img src={iconX} alt="dry spell icon" className="icon-r180" />
                        </div>
                    </div>
                    <div className="badge green">
                        <div>
                            <div>123</div>
                            <img src={iconX} alt="dry spell icon" className="icon-r180" />
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        goals: state.getIn(['calculations', 'goals', 'halfFull'])
    }
}

export default connect(mapStateToProps)(HalfFull)
