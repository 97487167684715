import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { callApi } from '../../../actions/api'
import { routes } from '../../../constants/routes'

import { GOALS } from '../../../constants/goals'
import { apiResourceEndpoint } from '../../../constants/routes'
import SHSForm from '../../utils/shs-form'

class GoalSelection extends React.Component {
    state = {
        goal: this.props.settings ? this.props.settings.getIn(['_computed', 'goalValue']) : null,
        showGoalText: null,
        shouldNavigate: false
    }

    setGoal = (goal) => {
        this.setState({ goal: goal.id })
    }

    submitFX = (formData, formId) => {
        const objData = {
            goal: this.state.goal,
            _key: 'SETTINGS'
        }
        if (this.props.settings.get('id')) {
            this.props.dispatch(callApi(apiResourceEndpoint('setting', 'UPDATE', this.props.settings.get('id')), objData, formId))
        } else {
            this.props.dispatch(callApi(apiResourceEndpoint('setting', 'CREATE'), objData, formId))
        }
    }

    successFX = () => {
        this.setState({ shouldNavigate: true })
    }

    renderGoalOptions = () => {
        let allGoals = []
        GOALS.forEach(goal => {
            allGoals.push(
                <div key={goal.id} className={`item ${this.state.goal === goal.id ? 'active' : ''}`} onClick={() => this.setGoal(goal)}>
                    <img src={goal.icon} alt={this.state.goal} className="icon" />
                    <div className="description">
                        <div className="title">
                            {goal.name}
                        </div>
                        {
                            this.state.showGoalText === goal.id ?
                                <p onClick={() => this.setState({ showGoalText: null })}>{goal.description}</p> :
                                <span className="clickable" onClick={() => this.setState({ showGoalText: goal.id })}>
                                    More info
                                </span>
                        }
                    </div>
                </div>
            )
        })
        return <div className="options">{allGoals}</div>
    }

    renderNextBtn = () => {
        return (
            <SHSForm
                submitFX={this.submitFX}
                successFX={this.successFX}
                buttonClass="btn"
                buttonLabel={this.state.goal ? 'Set Goal' : 'Select Above'}
                buttonDisabled={this.state.goal ? false : true} />
        )
    }

    render = () => {
        if (this.state.shouldNavigate) {
            if (this.props.fromSettings) {
                return <Navigate to={routes.settingsIndex} />
            }
            return <Navigate to={routes.onboardRegister} />
        }
        return (
            <div className="onboard">
                <h1>
                    What is  your goal?
                </h1>
                {this.renderGoalOptions()}
                {this.renderNextBtn()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        settings: state.get('settings')
    }
}

export default connect(mapStateToProps)(GoalSelection)
