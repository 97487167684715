import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import moment from 'moment'
import { buildRoutePath, routes } from '../../../constants/routes'

import glassFull from '../../../assets/imgs/icon-glass-full-01.svg'
import glassX from '../../../assets/imgs/icon-glass-x-01.svg'
import glassEmpty from '../../../assets/imgs/icon-glass-01.svg'

class Calendar extends React.Component {
    state = {}

    renderDates = () => {
        const { dates } = this.props
        let allDates = []
        dates.forEach(d => {
            let icon = <img src={glassEmpty} alt="No Data" />
            if (d.get('wasSober') === false) {
                icon = <img src={glassFull} alt="Drank" />
            } else if (d.get('wasSober')) {
                icon = <img src={glassX} alt="No Drink" />
            }
            allDates.push(
                <Link
                    key={d.get('date')}
                    to={buildRoutePath(routes.calendarDay, { dayDate: d.get('date') })}
                    className={`date ${d.get('wasSober') ? 'sober' : ''} ${d.get('wasSober') === false ? 'drunk' : ''}`}>
                    <span className="label-date">{ moment(d.get('date')).format('MMM D, YY') }</span>
                    <span className="label-day">{ moment(d.get('date')).format('ddd') }</span>
                    <span className="label-icon">
                        { icon }
                    </span>
                </Link>
            )
        })
        return allDates
    }

    renderUpsell = () => {
        const { settings, datesCount } = this.props
        if (!settings || settings.get('limited') === false || datesCount <= 31) {
            return null
        }
        return (
            <div className="onboard">
                <div className="prompt d-flex justify-content-center">
                    Your history is limited to 31 days.<br />
                    View your full history for only $5.
                </div>
                <Link to={routes.settingsIndex} className="next-btn active mt-0 mb-5">
                    Activate Premium
                </Link>
            </div>
        )
    }

    render = () => {
        return (
            <div className="history">
                {this.renderDates()}
                {this.renderUpsell()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const settings = state.get('settings')
    let dates = state.get('dates').sort((a, b) => {
        if (a.get('date') > b.get('date')) {
            return -1
        }
        return 1
    })
    const datesCount = dates.size
    if (settings && settings.get('limited') === false) {
        // do nothing
    } else {
        dates = dates.slice(0, 31)
    }
    return {
        datesCount,
        dates,
        settings
    }
}

export default connect(mapStateToProps)(Calendar)
