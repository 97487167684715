import React from 'react'
import { connect } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { callApi } from '../../../actions/api'
// import { manageApiData } from '../../../actions/local-api'

import { apiResourceEndpoint, routes } from '../../../constants/routes'

class PaymentOffer extends React.Component {
    state = {
        shouldRedirect: false
    }

    // showPremiumOffer = () => {
    //     return (
    //         <>
    //             <div className="prompt">
    //                 <p>Unlock Premium</p>
    //                 <p>For only $5, premium includes unlimited history and storing your data so you can access it from any device.</p>
    //             </div>
    //             <Link to={this.props.fromSettings ? routes.settingsPayment : routes.onboardPayment} className="next-btn active">
    //                 Activate Premium
    //             </Link>
    //             { !this.props.fromSettings &&
    //                 <span className="clickable skip-link" onClick={this.skipStep}>
    //                     I'll stick with free account
    //                 </span>
    //             }
    //         </>
    //     )
    // }

    skipStep = () => {
        this.props.dispatch(callApi(apiResourceEndpoint('setting', 'UPDATE', this.props.settings.get('id')), { onboardComplete: true }, -1))
        // this.props.dispatch(manageApiData({
        //     _key: 'SETTINGS',
        //     // limited: false,
        //     onboardComplete: true
        // }))
        this.setState({ shouldRedirect: true })
    }

    render = () => {
        if (this.state.shouldRedirect) {
            return <Navigate to={routes.index} />
        }
        return (
            <div className="onboard">
                <div className="prompt">
                    <h1>Unlock Premium</h1>
                    <hr/>
                    <p>For only $5, premium includes unlimited history and app notifications so you can track your progress year round.</p>
                </div>
                <Link to={this.props.fromSettings ? routes.settingsPayment : routes.onboardPayment} className="next-btn active">
                    Activate Premium
                </Link>
                { !this.props.fromSettings &&
                    <p className="clickable skip-link" onClick={this.skipStep}>
                        I'll stick with free account for now...
                    </p>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        settings: state.get('settings')
    }
}

export default connect(mapStateToProps)(PaymentOffer)
