import React from 'react'
import { connect } from 'react-redux'

import { Navigate } from 'react-router-dom'

import { routes } from '../../../constants/routes'
import { whereToGo } from '../../utils/router-logic'

import { fillDates } from '../../../actions/local-api'

import glass100 from '../../../assets/imgs/icons/dryspell-cups-05.svg'
import glass75 from '../../../assets/imgs/icons/dryspell-cups-04.svg'
import glass50 from '../../../assets/imgs/icons/dryspell-cups-03.svg'
import glass25 from '../../../assets/imgs/icons/dryspell-cups-02.svg'
import glass0 from '../../../assets/imgs/icons/dryspell-cups-01.svg'

const DRINK_FULLNESS = {
    100: glass100,
    75: glass75,
    50: glass50,
    25: glass25,
    0: glass0
}

class WelcomeCheers extends React.Component {
    state = {
        shouldNavigate: false,
        fullness: 0,
        fillDir: 'up',
        delay: 50
    }

    componentDidMount = () => {
        this.props.onTurnOffWelcomePath()
        this.props.dispatch(fillDates())
        // setTimeout(this.drainCup, 100)
        this.timer = setTimeout(() => {
            this.drainCup()
        }, this.state.delay)
        setTimeout(this.checkRedirect, 3000)
    }

    checkRedirect = () => {
        const { auth, dates, settings, user } = this.props
        const redirectKey = whereToGo(dates, settings, user, auth)
        clearTimeout(this.timer)
        this.setState({
            shouldNavigate: true,
            redirectKey
        })
    }

    drainCup = () => {
        let { fullness, fillDir } = this.state
        let delay = 50
        if (fillDir === 'up') {
            if (fullness === 100) {
                delay = 700
                this.setState({ fillDir: 'down', delay })
            } else {
                fullness = fullness + 25
                this.setState({ fullness, delay })
            }
        } else if (fullness === 0) {
            delay = 700
            this.setState({ fillDir: 'up', delay })
        } else {
            fullness = fullness - 25
            this.setState({ fullness, delay })
        }
        this.timer = setTimeout(() => {
            this.drainCup()
        }, this.state.delay)
    }

    render = () => {
        if (this.state.shouldNavigate) {
            switch (this.state.redirectKey) {
            case 'ONBOARD':
                return <Navigate to={routes.onboardIndex} />
            case 'TODAY':
                return <Navigate to={routes.today} />
            case 'GOALS':
                return <Navigate to={routes.onboardSetGoal} />
            case 'PURCHASE':
                return <Navigate to={routes.onboardPremium} />
            case 'REPORTS':
                return <Navigate to={routes.goalsIndex} />
            default:
                return <Navigate to={routes.onboardIndex} />
            }
        }
        return (
            <div id="welcome">
                <div className="logo">
                    <img src={DRINK_FULLNESS[this.state.fullness]} alt="Full Drink" className="icon" />
                    <p>
                        Drink tracking<br/>one day at a time.
                    </p>
                </div>
                <div className="made-by">
                    <span>Made by</span>
                    <a href="http://www.shadyhillstudios.com" className="my-2">
                        <img src="/shs-logo-white.png" alt="Shady Hill Studios" width={80} />
                    </a>
                    <a className="shs" href="http://www.shadyhillstudios.com">Shady Hill Studios</a>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.get('auth'),
        dates: state.get('dates'),
        settings: state.get('settings'),
        user: state.get('user')
    }
}

export default connect(mapStateToProps)(WelcomeCheers)
