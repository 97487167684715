import React from 'react'
import { connect } from 'react-redux'

import { callApi } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

import DrySpell from './dry-spell'
import HalfFull from './half-full'
import Moderator from './moderator'
import WeekendWarrior from './weekend-warrior'

import iconArrow from '../../../assets/imgs/icon-arrow-01.svg'
import iconX from '../../../assets/imgs/icons/dryspell-icon-x.svg'
import iconHalf from '../../../assets/imgs/icons/dryspell-icon-half.svg'
import iconWeekend from '../../../assets/imgs/icons/dryspell-icon-weekend.svg'
import iconCurve from '../../../assets/imgs/icons/dryspell-icon-curve.svg'

const goalLabels = {
    DRY_SPELL: { icon: iconX, label: 'DrySpell' },
    HALF_FULL: { icon: iconHalf, label: 'Half Full' },
    MODERATOR: { icon: iconCurve, label: 'Moderator' },
    WEEKEND_WARRIOR: { icon: iconWeekend, label: 'Weekend Warrior' }
}

class GoalsIndex extends React.Component {
    state = {
        curGoalIdx: 0,
        goalTypes: Object.keys(goalLabels)
    }

    componentDidMount = () => {
        this.props.dispatch(callApi(apiResourceEndpoint('historie', 'LIST', null, 'calculations'), {}))
        // re-order the goals based on user pref
        if (this.props.settings.hasIn(['_computed', 'goalValue'])) {
            let goal = this.props.settings.getIn(['_computed', 'goalValue'])
            let newGoals = this.state.goalTypes.filter(g => {
                return g !== goal
            })
            this.setState({ goalTypes: [goal, ...newGoals] })
        }
    }

    advanceGoals = (val) => {
        if (val === -1 && this.state.curGoalIdx !== 0) {
            this.setState({ curGoalIdx: this.state.curGoalIdx - 1 })
        } else if (this.state.curGoalIdx !== this.state.goalTypes.length - 1) {
            this.setState({ curGoalIdx: this.state.curGoalIdx + 1 })
        }
    }

    render = () => {
        let chart = null
        switch (this.state.goalTypes[this.state.curGoalIdx]) {
        case 'HALF_FULL':
            chart = <HalfFull />
            break
        case 'WEEKEND_WARRIOR':
            chart = <WeekendWarrior />
            break
        case 'MODERATOR':
            chart = <Moderator />
            break
        case 'DRY_SPELL':
        default:
            chart = <DrySpell />
        }
        let labelObj = goalLabels[this.state.goalTypes[this.state.curGoalIdx]]
        return (
            <div id="goal-holder">
                <div className="goal-nav">
                    <span
                        className={`arrow ${this.state.curGoalIdx === 0 ? 'disabled' : ''}`}
                        onClick={() => this.advanceGoals(-1)}>
                        <img src={ iconArrow } className="icon-r180" alt=">" />
                    </span>
                    <span className="title">
                        <img src={ labelObj.icon } alt="goals" />
                        <span>{ labelObj.label }</span>
                    </span>
                    <span
                        className={`arrow ${this.state.curGoalIdx === this.state.goalTypes.length - 1 ? 'disabled' : ''}`}
                        onClick={() => this.advanceGoals(1)}>
                        <img src={ iconArrow } alt=">" />
                    </span>
                </div>
                {chart}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        settings: state.get('settings')
    }
}

export default connect(mapStateToProps)(GoalsIndex)
