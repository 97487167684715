import React from 'react'
import { connect } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'

import { withRouterProps } from '../../utils/with-router-props'
import { nestedRoutePath, routes } from '../../../constants/routes'

import OnboardHome from './onboard-home'
import GoalSelection from './goal-selection'
import RegisterLogin from './register-login'
import ReminderSetting from './reminder-setting'
import PaymentOffer from './payment-offer'
import PaymentStep from './payment-step'

const ONBOARD_STEPS = ['GOAL_SELECTION', 'REMINDER', 'PURCHASE']

class OnboardIndex extends React.Component {
    state = {
        curStepIndex: 0,
        readyToRoute: false,
        redirectRoute: null
    }

    componentDidMount = () => {
        const { auth, settings } = this.props
        if (!auth.has('hasAuthId') || !auth.get('hasAuthId')) {
            // console.log('do not redirect')
        } else if (settings.get('goal')) {
            console.log('got in here because we have a goal')
            if (settings.get('reminder')) {
                if (settings.get('onboardComplete')) {
                    this.setState({ readyToRoute: true })
                } else {
                    this.setState({ curStepIndex: ONBOARD_STEPS.indexOf('PURCHASE') })
                }
            } else if (!settings.get('onboardComplete') && !this.state.readyToRoute && this.props.location.pathname !== routes.onboardPremium) {
                console.log('we should get a purchase ask?')
                console.log(this.props.location.pathname)
                console.log(routes.onboardPremium)
                // this.setState({
                //     readyToRoute: true,
                //     redirectRoute: routes.onboardPremium
                // })
                // this.setState({ readyToRoute: true, redirectRoute: routes.onboardPremium })
                // this.setState({ curStepIndex: ONBOARD_STEPS.indexOf('PURCHASE') })
            } else {
                console.log('this one')
                if (!this.state.readyToRoute) {
                    // this.setState({ readyToRoute: true })
                }
            }
            // else if (!this.state.readyToRoute && this.props.location.pathname !== routes.onboardReminder) {
            // this.setState({ readyToRoute: true })
            // this.setState({ readyToRoute: true, redirectRoute: routes.onboardReminder })
            // }
        } else {
            console.log('no goal?')
            console.log(settings.get('goal'))
            this.setState({ curStepIndex: ONBOARD_STEPS.indexOf('GOAL_SELECTION') })
        }
    }

    advanceStep = () => {
        let { curStepIndex } = this.state
        curStepIndex = curStepIndex + 1
        if (curStepIndex > ONBOARD_STEPS.length) {
            this.setState({ readyToRoute: true })
        } else {
            this.setState({ curStepIndex })
        }
    }

    render = () => {
        if (this.state.readyToRoute) {
            if (this.state.redirectRoute) {
                return <Navigate to={this.state.redirectRoute} />
            }
            return <Navigate to={routes.index} />
        }
        return (
            <Routes>
                <Route index element={<OnboardHome />} />
                <Route path={nestedRoutePath(routes.onboardIndex, routes.onboardSetGoal)} element={<GoalSelection />} />
                <Route path={nestedRoutePath(routes.onboardIndex, routes.onboardRegister)} element={<RegisterLogin />} />
                <Route path={nestedRoutePath(routes.onboardIndex, routes.onboardReminder)} element={<ReminderSetting />} />
                <Route path={nestedRoutePath(routes.onboardIndex, routes.onboardPremium)} element={<PaymentOffer />} />
                <Route path={nestedRoutePath(routes.onboardIndex, routes.onboardPayment)} element={<PaymentStep />} />
            </Routes>
        )
        // switch (ONBOARD_STEPS[this.state.curStepIndex]) {
        // case 'GOAL_SELECTION':
        //     return <GoalSelection onAdvanceStep={this.advanceStep} />
        // case 'REMINDER':
        //     return <ReminderSetting onAdvanceStep={this.advanceStep} />
        // // case 'PERSONAL':
        // //     return <div>can we add some metrics</div>
        // case 'PURCHASE':
        //     return <PaymentOffer onAdvanceStep={this.advanceStep} />
        // default:
        //     return <Navigate to={routes.index} />
        // }
    }
}

const mapStateToProps = (state) => {
    return {
        settings: state.get('settings'),
        auth: state.get('auth')
    }
}

export default withRouterProps(connect(mapStateToProps)(OnboardIndex))
