import React from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../../../constants/routes'

import iconX from '../../../assets/imgs/icons/dryspell-icon-x.svg'
import iconBase from '../../../assets/imgs/icons/dryspell-icon-base.svg'

class OnboardHome extends React.Component {
    state = {
        openedFromInstall: window.matchMedia('(display-mode: standalone)').matches,
        agent: navigator.userAgent
    }

    componentDidMount = () => {
        // check if we can capture the install link action...
        // can we load specific share icons based on the agent?
    }

    render = () => {
        return (
            <div className="onboard">
                <div className="image-wrapper">
                    <img src={iconBase} alt="Dry Spell App" className="" width="100" />
                    <img src={iconX} alt="Dry Spell App" className="icon-r180 filter-orange" width="100" />
                </div>
                <h1>Welcome!</h1>
                <hr/>
                <div className="prompt">
                    <p>
                        DrySpell is a web application that helps you track the days when you do or do not drink alcohol.
                    </p>
                    { !this.state.openedFromInstall &&
                        <>
                            <hr/>
                            <h2>Install as Phone App</h2>
                            <p className="mt-5">
                                This is a <u><a href="https://en.wikipedia.org/wiki/Progressive_web_application" target="_blank" rel="noreferrer">PWA</a></u> and best experienced like an app on your phone.
                            </p>
                            <p className="mt-5">
                                To install the app on your phone, simply click the share icon and then select the "Add to Desktop" option.
                            </p>
                        </>
                    }
                    <Link className="next-btn" to={routes.onboardSetGoal}>
                        Get Started
                    </Link>
                    <div className="mt-5 text-center">
                        Already registered? <Link to={routes.settingsRegister}>Sign In</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default OnboardHome
