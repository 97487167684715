import { fromJS } from 'immutable'

const INITIAL_STATE = fromJS({})

const authCodeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case `SAVE_AUTH_HANDLER`:
        return state
            .set('authCode', action.code)
            .set('authAction', action.action)
            .set('authTempEmail', action.email)
    case 'AUTH_HANDLER_ERROR':
        return state
            .set('hasError', true)
    case `REMOVE_AUTH_HANDLER`:
        return INITIAL_STATE
    case `KILL_STATE`:
        return fromJS({})
    default:
        return state
    }
}

export default authCodeReducer
