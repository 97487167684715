import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { withRouterProps } from '../../utils/with-router-props'
import { nestedRoutePath, routes } from '../../../constants/routes'

import GoalSelection from '../onboard/goal-selection'
import ReminderSetting from '../onboard/reminder-setting'
import PaymentOffer from '../onboard/payment-offer'
import PaymentStep from '../onboard/payment-step'
import PaymentReceipt from '../onboard/payment-receipt'
import RegisterLogin from '../onboard/register-login'
import SettingsHome from './home'
import AboutDrySpell from './about'

class SettingsIndex extends React.Component {
    state = {}

    render = () => {
        return (
            <Routes>
                <Route path={nestedRoutePath(routes.settingsIndex, routes.settingsSetGoal)} element={<GoalSelection fromSettings={true}/>} />
                <Route path={nestedRoutePath(routes.settingsIndex, routes.settingsReminder)} element={<ReminderSetting fromSettings={true} />} />
                <Route path={nestedRoutePath(routes.settingsIndex, routes.settingsRegister)} element={<RegisterLogin fromSettings={true} />} />
                <Route path={nestedRoutePath(routes.settingsIndex, routes.settingsPremium)} element={<PaymentOffer fromSettings={true} />} />
                <Route path={nestedRoutePath(routes.settingsIndex, routes.settingsPayment)} element={<PaymentStep fromSettings={true} />} />
                <Route path={nestedRoutePath(routes.settingsIndex, routes.settingsReceipt)} element={<PaymentReceipt />} />
                <Route path={nestedRoutePath(routes.settingsIndex, routes.settingsAbout)} element={<AboutDrySpell />} />
                <Route index element={<SettingsHome />} />
            </Routes>
        )
    }
}

export default withRouterProps(SettingsIndex)
