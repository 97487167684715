import glassHalf from '../assets/imgs/icons/dryspell-icon-half.svg'
import glassWeekend from '../assets/imgs/icons/dryspell-icon-weekend.svg'
import glassCurve from '../assets/imgs/icons/dryspell-icon-curve.svg'
import glassX from '../assets/imgs/icons/dryspell-icon-x.svg'


export const GOALS = [
    {
        id: 'HALF_FULL',
        name: 'Half Full',
        icon: glassHalf,
        description: 'The idea behind this goal is to not drink alcohol on more days than you do. Stay balanced and the glass will always be at least half full.'
    },
    {
        id: 'WEEKEND_WARRIOR',
        name: 'Weekend Warrior',
        icon: glassWeekend,
        description: 'This goal seeks to limit drinking to weekends and holidays. Achieving this goal means not drinking on weeknights.'
    },
    {
        id: 'MODERATOR',
        name: 'The Moderator',
        icon: glassCurve,
        description: 'Track your drinks per week and try to stay under the definition of moderate drinking (a max of 7 per week for women and 14 for men). Anything in moderation.'
    },
    {
        id: 'DRY_SPELL',
        name: 'Dry Spell',
        icon: glassX,
        description: 'Track your sobriety streak. If you can do it for a day, you can do it for a week. You got this.'
    }
]
