import immutable from 'immutable'
import * as helpers from './helpers'

const INITIAL_STATE = immutable.fromJS([])

const apiCacheReducer = (state = INITIAL_STATE, action) => {
    let objectIndex = -1
    // let newState = state
    switch (action.type) {
    case `SAVE_API_CALL`:
        objectIndex = helpers.getObjectIndex(state, action.data.url, 'url')
        if (objectIndex === -1) {
            return helpers.addObject(state, action.data)
        }
        return helpers.updateObject(state, action.data, objectIndex)
    case `CLEAR_API_CALL`:
        return state.filter(entry => {
            return action.data.url !== entry.get('url')
        })
    case `KILL_STATE`:
        return INITIAL_STATE
    default:
        return state
    }
}

export default apiCacheReducer
